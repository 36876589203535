import React from 'react';

export default function Product(props) {
  const Base_Url = 'http://www.accounts.need.ae:82';
  const { product, onAdd } = props;
  return (
    <div className="child">
      <img className="small" src={Base_Url+ product.thumb} alt={product.itemName} />
      <h3>{product.itemName}</h3>
      <div>AED {product.offerprice}</div>
      <div>
        <button onClick={() => onAdd(product)}>Add To Cart</button>
      </div>
    </div>
  );
}
