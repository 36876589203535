 import React from 'react';
 import Product from './Product';
 const Base_Url = 'http://www.accounts.need.ae:82';
 
// export default function Main(props) {
//   const { products, onAdd } = props;
//   return (
//     <main className="block col-2">
//       <h2>Products</h2>
//       <div className="row">
//         { products.map((product) => (
//           <Product key={product.id} product={product} onAdd={onAdd}></Product>
//         ))}
//       </div>
//     </main>
//   );
// }
class Main extends React.Component {
  constructor(props) {    
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }
  componentDidMount() {
    fetch(Base_Url + '/Home/GetProducts?cart=True&type=2')
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.rows
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render(props) {
    
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <main className="block">
              <h2>Products</h2>
               <div className="row parent">
                { items.map((product) => (
                <Product key={product.id} product={product} onAdd={this.props.onAdd}></Product>
                ))}
              </div>
             </main>
      );
    }
  }
}
export default Main;