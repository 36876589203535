import React from 'react';
const Base_Url = 'http://www.accounts.need.ae:82';
export default function Basket(props) {
  const { cartItems, onAdd, onRemove } = props;
  const itemsPrice = cartItems.reduce((a, c) => a + c.qty * c.offerprice, 0);
  const taxPrice = itemsPrice * 0.05;
  const shippingPrice = 0;
  const totalPrice = itemsPrice + taxPrice + shippingPrice;
  const onCheckout = () => {
    const contactName = document.getElementById('txtname').value
    const contactNumber = document.getElementById('txtnumber').value
     if(contactName===''||contactNumber===''){
       alert('Please fill name and number')
       return false;
     }
    const data = new FormData();
    data.append('contactName',contactName);
    data.append('contactNumber',contactNumber);
    data.append('total',itemsPrice);

    var index = 0;
    for(var folder of cartItems){
        data.append("itemsList[" + index + "].id", folder.id);
        data.append("itemsList[" + index + "].price", folder.offerprice);
        data.append("itemsList[" + index + "].qty", folder.qty);
        onRemove(folder)
        index++;
    }


    fetch(Base_Url+'/Home/CreateSalesOrder', {
        method: 'POST',
        headers: new Headers(),
        body: data
        
    }).then((res) => res.json())
        .then((data) => {
          if(data==='Success'){
            alert("Order Created")
            window.location.reload(false);
          }
        })
        .catch((err) => console.log(err))
  };

  return (
    <aside className="block basket">
      <h2>Cart Items</h2>
      <div>
        {cartItems.length === 0 && <div>Cart is empty</div>}
        {cartItems.map((item) => (
          <div key={item.id} className="row">
            <div className="col-2">{item.itemName}</div>
            <div className="col-2">
              <button onClick={() => onRemove(item)} className="remove">
                -
              </button>{' '}
              <button onClick={() => onAdd(item)} className="add">
                +
              </button>
            </div>

            <div className="col-2 text-right">
              {item.qty} x AED {item.offerprice.toFixed(2)}
            </div>
          </div>
        ))}

        {cartItems.length !== 0 && (
          <>
            <hr></hr>
            <div className="row">
              <div className="col-2">Items Price</div>
              <div className="col-1 text-right">AED {itemsPrice.toFixed(2)}</div>
            </div>
            <div className="row">
              <div className="col-2">Tax Price</div>
              <div className="col-1 text-right">AED {taxPrice.toFixed(2)}</div>
            </div>
            <div className="row">
              <div className="col-2">Shipping Price</div>
              <div className="col-1 text-right">
                AED {shippingPrice.toFixed(2)}
              </div>
            </div>           
            <div className="row">
              <div className="col-2">
                <strong>Total Price</strong>
              </div>
              <div className="col-1 text-right">
                <strong>AED {totalPrice.toFixed(2)}</strong>
              </div>

            </div>    

            <div className="row">
              <div className="col-1">
                <strong>CustomerName</strong>
              </div>
              <div className="col-2">
                <input type="Text" className="" id="txtname" required></input>
              </div>
              
            </div>   
            <div className="row">
              <div className="col-1">
                <strong>CustomerNumber</strong>
              </div>
              <div className="col-2">
                <input type="Text" className="" id="txtnumber" required></input>
              </div>

            </div> 


            <hr />
            <div className="row">
              <button onClick={onCheckout}>
                Order
              </button>
            </div>
          </>
        )}
      </div>
    </aside>
  );
}
