import React from 'react';
import Logo from './NeedLogo.jpeg';
export default function Header(props) {
  return (
    <header className="block row center">
      <img height="100 rem" width="100 rem" src={Logo}></img>
      
        <a href="#/">
          <h1>Wholesale Baqala</h1>
          <h1>0504769695, 0566626042</h1>
        </a>
    
        <a href="#/cart">
          Cart{' '}
          {props.countCartItems ? (
            <button className="badge">{props.countCartItems}</button>
          ) : (
            ''
          )}
        </a>{' '}
        <a href="#/signin"> SignIn</a>
    </header>
  );
}
